<template>
  <div class="note">
    <van-tabs class="tabs" type="card" @change="onChange">
      <van-tab title="福利广播" name="1" :disabled="loading" />
      <van-tab title="系统消息" name="2" :disabled="loading" />
      <van-empty v-if="!notes || notes.length < 1" description="暂无数据" />
      <van-pull-refresh v-model="pullLoading" @refresh="refresh">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="getNote"
          class="note-list"
        >
          <van-cell
            span="12"
            v-for="item in notes"
            :key="item.note_id"
            class="item"
            :data-id="item.note_id"
          >
            <div class="title van-ellipsis">
              <i
                class="note-icon"
                :class="{
                  'note-wel-icon': item.type === 1,
                  'note-ord-icon': item.badge === 3,
                }"
              />
              {{ item.title }}
            </div>

            <div v-if="item.pic" class="note-pic">
              <img :src="item.pic" />
              <div v-if="item.attach" class="status">
                {{ item.attach | ordderStatus }}
              </div>
            </div>

            <div class="content van-multi-ellipsis--l2">
              {{ item.content }}
            </div>
            <div class="end van-hairline--top">
              <div class="fl">{{ item.created_at | formatDate }}</div>
              <div class="fr" v-if="item.link_type !== 0">
                <span class="link" @click="toWatch(item)">
                  <span v-if="item.badge === 3">查看详情</span>
                  <span v-else>现在去看看</span>
                  <van-icon name="arrow" />
                </span>
              </div>
            </div>
            <van-icon name="delete-o" @click="delNote(item.note_id)" />
            <i v-if="item.status === 0" class="new-icon" />
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "Note",
  data() {
    return {
      type: 1, //1：福利广播；2：系统消息
      page: 0,
      notes: null,
      loading: false,
      finished: false,
      pullLoading: false,
    };
  },
  computed: {
    NoteBadge() {
      return this.$store.state.NoteBadge;
    },
  },
  methods: {
    onChange(type) {
      if (this.type !== type) {
        this.type = type;
        this.page = 0;
        this.notes = [];
        this.getNote();
      }
    },
    toWatch(item) {
      if (item.badge === 3) {
        //订单
        this.$router.push({
          name: "OrderDetail",
          params: { oid: item.link_id },
        });
        return false;
      } else if (item.status === 2) {
        //活动已结束
        this.$toast("活动已经结束啦，下次早点参加哦~");
        return false;
      } else {
        location.href = item.link_url;
      }
    },
    delNote(id) {
      let typeText = this.type === 1 ? "福利广播" : "系统消息";
      this.$dialog
        .confirm({
          message: "确定要删除该条" + typeText + "吗？",
        })
        .then(() => {
          this.$ajax.post("/user/note/remove/", { note_id: id }).then((res) => {
            for (let i = 0; i < this.notes.length; i++) {
              let note = this.notes[i];
              if (note.note_id === id) {
                this.notes.splice(i, 1);
                break;
              }
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    refresh() {
      this.page = 0;
      this.getNote();
    },
    getNote() {
      this.$ajax
        .post("/user/note/index/", {
          type: this.type,
          page_no: this.page,
          page_size: 10,
        })
        .then((res) => {
          let badge = this.NoteBadge;
          res.data.forEach((item) => {
            if (item.sataus === 0 && badge > 0) {
              badge--;
            }
          });
          this.$store.commit("NoteBadge", badge);
          //res.data.badge 1.新消息；2.热门；3.订单
          if (this.page === 0) {
            this.notes = res.data;
          } else {
            if (res.data) {
              this.notes = [...this.notes, ...res.data];
            }
          }
          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
          this.loading = false;
          this.pullLoading = false;
        });
    },
  },
  filters: {
    ordderStatus(val) {
      //因为val是从1开始，所以数组前留一个占位
      let status = ["", "待付款", "已付款", "待收货", "已完成", "已关闭"];
      return status[val];
    },
  },
};
</script>
